
import Navbar from "../../components/Navbar";
import resume from '../../icons/resume.png'; // Tell webpack this JS file uses this image

export default function ResumePage() {


    return <div >



        <Navbar />

        <div className="title">
        <h2 className="">Resume </h2>
        </div>
        <img  alt ="error"style={{display:"flex",width:"100vw",margin:"auto"}} src={resume}></img>
    </div>
}