import reactIcon from '../icons/react.svg'; // Tell webpack this JS file uses this image
import flutterIcon from '../icons/flutter.svg'; // Tell webpack this JS file uses this image
import javaIcon from '../icons/java.svg'; // Tell webpack this JS file uses this image
import springIcon from '../icons/spring.svg'; // Tell webpack this JS file uses this image
import aspIcon from '../icons/aspdotnet.svg'; // Tell webpack this JS file uses this image
import sqlIcon from '../icons/sql.svg'; // Tell webpack this JS file uses this image


export default class Project {


    constructor(pName, imgs, description, tech, github = "https://github.com/GoolsbyJulien") {
        this.pName = pName;
        this.imgs = imgs;
        this.tech = tech;
        this.description = description;
        this.github = github;
    }

    getTech(size = 3) {
        return getTechHelper(size,this.tech);
    }

}

export function getTechHelper(size,tech) {


    const icons = tech.map((c) => {

        switch (c) {
            case "R":
                return (<TechIcon key ={c} size={size} icon={reactIcon} />);
            case "S":
                return (<TechIcon key ={c} size={size} icon={springIcon} />);
            case "A":
                return (<TechIcon key ={c}size={size} icon={aspIcon} />);
            case "F":
                return (<TechIcon key ={c}size={size} icon={flutterIcon} />);
            case "J":
                return (<TechIcon key ={c} size={size} icon={javaIcon} />);
            case "Q":
                return (<TechIcon key ={c}size={size} icon={sqlIcon} />);
            default:
                return (<div>?</div>);

        }
    });
    return icons;

}
function TechIcon({ size, icon }) {

    return (<img alt='error' style={{ width: `${size}rem`, height: `${size}rem`, border: "none" }} src={icon}></img>)
}

