
import styles from './ProjectPreview.module.css';
import { useNavigate } from "react-router-dom";

export default function ProjectPreview({ project, update }) {

    const navigate = useNavigate();

    return (
        <div onMouseLeave={() => { update("") }} onMouseEnter={() => {  }} className={styles.container}>

            <div className={styles.techIcons}>

                {project.getTech()}
            </div>
            <img alt='error' src={project.imgs[0]} />



            <div className={styles.projectTitle}>



                <p style={{ padding: 0, margin: 0, display: "inline-block", textAlign: "center" }}>
                    {project.pName}
                </p>

            </div >
            <button style={{ backgroundColor: "white", color: "black", border: "none", marginTop: "5%" }} onClick={() => { navigate("/projects/details", { state: { project: project } }) }}> Learn More</button>

        </div>);
}