import { getTechHelper } from "../../../classes/Project";
import Navbar from "../../../components/Navbar";
import { useLocation } from 'react-router-dom';

export default function ProjectDetails() {
    let location = useLocation();

    let project = location.state.project;

    return <div >



        <Navbar />

        <div className="title" style={{ fontSize: "2vw" }}>
            <h2 style={{ marginBottom: '0' }}>
                {project.pName}
                <a href={project.github} target="_blank" rel="noreferrer"> <img alt="github" style={{ width: "1em" }} src="https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png"></img> </a>

            </h2>
            {getTechHelper(5, project.tech)}

            <p style={{ margin: "5%" }}>{project.description}</p>

            <h3 style={{ marginTop: "10rem" }}> Gallery</h3>
            {project.imgs.map((img) => { return <img className="gal" alt="error" style={{}} src={img}></img> })}
        </div>
    </div>
}