

import './App.css';
import Homepage from './pages/Home Page/homepage';
import { Routes, Route } from 'react-router-dom';
import ProjectPage from './pages/Project Page/ProjectPage';
import ProjectDetails from './pages/Project Page/Project Details/ProjectDetails';
import AboutPage from './pages/About Page/AboutPage';
import ResumePage from './pages/Resume Page/ResumePage';




function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/projects" element={<ProjectPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/resume" element={<ResumePage />} />
      <Route path="/projects/details" element={<ProjectDetails />} />
    </Routes>

  );

}

export default App;
