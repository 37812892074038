import socialIcon from '../../icons/linkedin.svg'; // Tell webpack this JS file uses this image
import Navbar from "../../components/Navbar";
import styles from "./about.module.css";
import Project from '../../classes/Project';
import { useNavigate } from "react-router-dom";


//internship
import i1 from "../../imgs/internship/i0.jpg"
import i2 from "../../imgs/internship/i1.jpg"
import i3 from "../../imgs/internship/i2.jpg"

export default function AboutPage() {

    const navigate = useNavigate();

    const internshipProject = new Project("Yellow Internship", [i3, i2, i1], "During the summer of 2023, I had the opportunity to intern at Yellow. I worked under the mentorship of Byrn Aytes to work on React. js-based applications that utilized Amazon Web Services. I use Linux command line environments to manage data on servers. I worked with the scrum team completed sprints and brought my changes in front of testing and then into production. I did code reviews to maintain professional code. ", [])
    return <div >


        <Navbar />

        <div className='title' >
            <h2 style={{ marginBottom: "0px" }} className="">About </h2>
        </div>
        <p style={{ fontSize: "2rem", textAlign: "center" }}>A Software Developer, interested in front-end technologies such as Flutter and React.js, and also has an understanding of full stack development. Understanding of sprint-based workflow with professional experience working in a scrum team. Has industry experience through an internship. Graduated from the University of Central Missouri with a degree in computer science.</p>


        <section className={styles.cardSection} style={{ width: "100%" }}>


            <div className={styles.cards}>
                <div style={{ backgroundColor: "green" }} className={styles.card}>
                    <h3> Experience</h3>

                    <p> I have been learning about software development for many years. I became interested in high school initially creating small projects in Java. I then got accepted into the Northland Caps program which allowed me to experience an internship at Cerner. <br /> <br />In the summer of 2023 I have another opportunity to intern, this time at Yellow. I have since been growing and learning on my own, learning new skills and creating projects as well as actively participating in events where I am challenged to produce a project in a short amount of time     </p>

                    <button onClick={() => { navigate("/projects/details", { state: { project: internshipProject } }) }}> More About Internship</button>
                </div>

                <div className={styles.card}>
                    <h3> Education</h3>

                    <p>
                        In May of 2024, I graduated from the <span style={{ color: '#cf202e' }}>University of Central Missouri</span>  with a bachelors degree in <b>Computer Science</b> with a focus in Software Development and minor in Cyber Security.
                    </p>
                </div>
                <div className={styles.card}>
                    <h3> Socials</h3>
                    <div style={{ display: "flex", width: "100%" }}>
                        <a style={{ margin: "auto" }} href='https://www.linkedin.com/in/julien-goolsby-07b65016b/' target="_blank" rel="noreferrer"><img alt="error" width={60} src={socialIcon} /> </a>
                    </div>
                </div>
            </div>
        </section>

    </div >
}