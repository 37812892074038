
import { useState } from "react";

import ProjectPreview from '../../components/ProjectPreview';
import Project from "../../classes/Project";
import Navbar from "../../components/Navbar";

//budget

import b1 from "../../imgs/budget/347248846-d55cfc0a-905d-4ffc-b95a-047391d4206e.png"
import b2 from "../../imgs/budget/347248849-8a4dd366-d664-4629-852c-242a98d7e3f8.png"
import b3 from "../../imgs/budget/347248850-c3b225ab-fa8f-49a2-82ed-f9316222c926.png"

//interview

import i1 from "../../imgs/interview/interview.png"

//map
import m1 from "../../imgs/map/m1.png"
import m2 from "../../imgs/map/m2.png"
import m3 from "../../imgs/map/m3.png"
import m4 from "../../imgs/map/m4.png"

//hack

import h1 from "../../imgs/hack/347251052-ef9db680-552c-4004-a644-d55671e891f1.png"
import h2 from "../../imgs/hack/347269516-2e004631-7731-4a89-b86d-7c72dac79c33.png"
import h3 from "../../imgs/hack/347269522-54c23255-5350-47fe-8472-689c4853d981.png"
import h4 from "../../imgs/hack/347269525-31a80c05-a3ad-4781-b4b2-615cd0f25c4e.png"
import h5 from "../../imgs/hack/1712512294626.jpg"

//habbit
import t1 from "../../imgs/habbit/t1.png"
import t2 from "../../imgs/habbit/t2.png"
import t3 from "../../imgs/habbit/t3.png"
import t4 from "../../imgs/habbit/t4.png"

export default function ProjectPage() {




    const projects = [
        new Project(
            "Budget App", [b2, b1, b3,], "This is a project I worked on with a large team of 8. My role on this project was to spearhead the frontend built in React.js and provide smooth integration with the Springboot backend. This project is fitted with a login system, with user data stored in a SQL database. The user can then track and reflect on month to month finances to reach their budget.", ["J", "S", "Q"]
            , "https://github.com/Syntax-Savants/SE_BudgetApp"),
        new Project(
            "Show Me Cupboard", [h1, h2, h3, h4, h5], "A Hack-a-thon app that seeks to connect food pantries with restaurants with food surplus. Users are able to go through a list of food pantries in the state of Missouri to find one that can accept food. All data on food pantries is provided by the Missouri government API. The API provided an address, phone number and more the user can use to get in contact. The website also provides useful information such as a calculator so the user can find out if they can get a tax break on the donation. Won most promising solution at Central Plains Conference", ["R"]
            , "https://github.com/GoolsbyJulien/SpringHackathon2024"),

        new Project(
            "Habit Tracker", [t1, t2, t3, t4], "Habit Tracker is a cross platform app I built in 2020. The issue this app is made to help with is the process of starting a new habit. For example, say you want to code once per day. Using this app you can easily create a habit profile for it and each day you can gain a small sense of pride by marking you did said habit.\n\nThat was my goal for this project, and I hope to have achieved that! ", ["F"]
            , "https://github.com/GoolsbyJulien/HabitTrackerApp"),
        new Project(
            "Map Builder", [
            m1, m2, m3, m4], "The senior project that I lead as a scrum master. A desktop app using java to generate maps and allow the user to customize and interact with the map. This project uses a Perlin noise algorithm, Swing GUI Framework, Graphics Rendering, and custom serialization. The user can generate a random map, save and load, and maintain a state.", ["J"]
            , "https://github.com/GoolsbyJulien/Senior-Project"
        ),

        new Project(
            "Interview App", [i1],

            "This is an app I started in May 2024. It is a web application aimed at helping job seekers practice interview questions and interviewing in front of a computer. It utilizes speech-to-text to record the user's answers and gives feedback to them. I found it personally useful to practice my interviewing skills. I want to expand this to use payment methods per interview round, this could be a great project for that."

            , ["R", "A"]
            , "https://github.com/GoolsbyJulien/Interview-App"),

    ];

    const [backgroundImage, setBackgroundImage] = useState("");

    return (
        <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>

            <Navbar />
            <header className="App-header">

                <div className="title">
                    <h2 style={{}}> Projects</h2>
                </div>

                <div style={{ maxWidth: "100vw" }}>
                    {projects.map((project) => {
                        return < ProjectPreview key={project.pName} project={project} update={(d) => setBackgroundImage(d)} />
                    })}
                </div>

            </header>
        </div>
    );
}

