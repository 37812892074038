
import { Link } from 'react-router-dom';
import styles from './homepage.module.css';


function Homepage() {

    return (
        <div className="App" >

            <header className={styles.header}>


                <div style={{ backgroundColor: "#FE6261", width: "100%", height: "50%", margin: 0, display: "flex" }}>

                    <div style={{ display: "block", margin: "auto", verticalAlign: "", padding: "30px", fontSize: "5vw", }}>
                        <h1 style={{ margin: 0 }} >Julien Goolsby</h1>

                        <h2 style={{ fontSize: "2rem", margin: 0 }}> Software Developer</h2>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>


                    <ul className={styles.list}>

                        <Link to="/about">About</Link>
                        <Link to="/projects">Projects</Link>
                        <Link to="/resume">Resume</Link>

                    </ul>
                </div>
            </header>
        </div>
    );
}

export default Homepage;