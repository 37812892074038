
import styles from './navbar.module.css';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import { useState } from "react";

function Navbar({ logged = true, pageID, extraElement }) {
    const [isOpen, setOpen] = useState(false)




    return (
        <nav className={styles.navbar}>

            <ul className={`${styles.navbarLinks} ${styles.list} ${isOpen ? "" : styles.min}`}>

                <div style={{ display: "flex" }} className={styles.hamburger}>
                    <Hamburger toggled={isOpen} toggle={setOpen} color='white' />
                </div>
                <li className={isOpen ? "" : styles.hide}>

                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/projects">Projects</Link>
                    <Link to="/resume">Resume</Link>

                </li>
            </ul>
        </nav>
    );
}

export default Navbar;